const { createHigherOrderComponent } = wp.compose;

// Add component
import ToolBar from '../components/toolbar';

export default  createHigherOrderComponent( ( BlockEdit ) => {
	return ( props ) => {
		return (
			<>
				<BlockEdit { ...props } />
				<ToolBar />
			</>
		);
	};
}, 'renderAiContentHelper' );
