import SinglePrompt from "./singlePromptItem";
export default function PromptItems({popOver}) {
	function removePopOver() {
		popOver();
	}
	return(
		<>
			<div className={'aich_custom_dropdown'}>
			{
				aich_ajax.prompts.map((category, index) => {
					return (
						<div className={'category_name'} key={index}>
							<div className={'category_heading'}>
								<strong>{category.group_language}</strong>
								<span>
									<svg width="10" height="5" viewBox="0 0 10 5" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path fill-rule="evenodd" clip-rule="evenodd" d="M0.997009 3.96331L5 0.324213L9.003 3.96331L8.33033 4.70325L5 1.67567L1.66968 4.70325L0.997009 3.96331Z" fill="#757575"/>
									</svg>
								</span>
							</div>
							<SinglePrompt removePopOver={removePopOver} prompts={category} lang={category.prompt_language} />
						</div>
					)
				})
			}
			</div>
		</>
	)
}
