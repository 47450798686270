"use strict";

import { addFilter } from '@wordpress/hooks';
import './style.scss';
// import aichTextControls from "../components/aichTextControls"
import renderAiContentHelper from "../components/render";
addFilter(
	'editor.BlockEdit',
	'aich/tex-controls',
	renderAiContentHelper,
);
