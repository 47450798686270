import React from "react";
import {__} from '@wordpress/i18n';
import apiFetch from '@wordpress/api-fetch';
import {Button, Modal} from '@wordpress/components';

class SinglePromptItem extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			selectedText: '',
			modalOpen: false,
			prompts: [],
			modalContent: '',
			generatedContent: '',
			modalTitle: '',
			generatedContentType: '',
			showSettingsBtn: false,
		}
	}
	async wpAcpLoadingSpinner() {
		return `<span class="aich-loading"></span>`;
	}

	// Selected Block
	getSelectedBlockClientIds() {
		let selectedBlockClientIds = wp.data.select('core/block-editor').getMultiSelectedBlockClientIds();

		if (selectedBlockClientIds.length === 0) {
			selectedBlockClientIds = [wp.data.select('core/block-editor').getSelectedBlockClientId()];
		}

		return selectedBlockClientIds;
	}

	getAdjustedSelections(selectedBlockClientIds) {
		const selectionStart = wp.data.select('core/block-editor').getSelectionStart();
		const selectionEnd = wp.data.select('core/block-editor').getSelectionEnd();

		if (selectionStart.clientId === selectionEnd.clientId) {
			return [selectionStart, selectionEnd];
		}

		let adjustedSelectionStart = selectionStart;
		let adjustedSelectionEnd = selectionEnd;
		if (selectedBlockClientIds.length > 0 && selectedBlockClientIds[0] === selectionEnd.clientId) {
			adjustedSelectionStart = selectionEnd;
			adjustedSelectionEnd = selectionStart;
		}

		return [adjustedSelectionStart, adjustedSelectionEnd];
	}

	getAllBlockContentsRecursively(blockClientIds, selectionStart, selectionEnd) {
		let content = '';
		blockClientIds.forEach(blockClientId => {
			const block = wp.data.select('core/block-editor').getBlock(blockClientId);
			let contentOfBlock = this.extractBlockContent(block);

			const richText = wp.richText.create({html: contentOfBlock});

			let plainText = richText.text;
			let start = 0;
			let end = plainText.length;

			if (selectionStart.clientId === blockClientId && 'offset' in selectionStart) {
				start = selectionStart.offset;
			}

			if (selectionEnd.clientId === blockClientId && 'offset' in selectionEnd) {
				end = selectionEnd.offset;
			}

			plainText = plainText.substring(start, end);

			content += "\n" + plainText;
			if (block.innerBlocks.length > 0) {
				content += this.getAllBlockContentsRecursively(block.innerBlocks.map(block => block.clientId));
			}
		});

		return content;
	}

	extractBlockContent(block) {
		let content = '';
		if ('content' in block.attributes) {
			content = block.attributes.content;
		} else if ('citation' in block.attributes) {
			content = block.attributes.citation;
		} else if ('value' in block.attributes) {
			content = block.attributes.value;
		} else if ('values' in block.attributes) {
			content = block.attributes.values;
		} else if ('text' in block.attributes) {
			content = block.attributes.text;
		}

		return content;
	}

	string_to_slug (str) {
		str = str.replace(/^\s+|\s+$/g, ''); // trim
		str = str.toLowerCase();

		// remove accents, swap ñ for n, etc
		var from = "àáãäâèéëêìíïîòóöôùúüûñç·/_,:;";
		var to   = "aaaaaeeeeiiiioooouuuunc------";

		for (var i=0, l=from.length ; i<l ; i++) {
			str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
		}

		str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
			.replace(/\s+/g, '-') // collapse whitespace and replace by -
			.replace(/-+/g, '-'); // collapse dashes

		return str;
	}

	async getSelectedContent() {
		let multiSelectedBlockClientIds = this.getSelectedBlockClientIds();
		let [selectionStart, selectionEnd] = this.getAdjustedSelections(multiSelectedBlockClientIds);

		let allContent = this.getAllBlockContentsRecursively(
			multiSelectedBlockClientIds,
			selectionStart,
			selectionEnd
		);

		return allContent.trim()
	}

	loader() {
		// Added loader
		const wrapper = document.createElement("div");
		wrapper.setAttribute("id", "aich-loading-wrapper");
		document.getElementById("editor").appendChild(wrapper)

		const spinner = document.createElement("span");
		spinner.setAttribute("class", "aich-loading");
		document.getElementById("aich-loading-wrapper").appendChild(spinner)
	}

	removeLoader() {
		document.getElementById("aich-loading-wrapper").remove();
	}

	async fetchRestApiGenerateContent(lang, index, prompt, promptTitle) {
		if (!aich_ajax.api_key_is_valid) {
			this.setState({
				modalOpen: true,
				modalTitle: __('Openai api key invalid', 'wp-ai-co-pilot'),
				modalContent: __('Please set openai api key.', 'wp-ai-co-pilot'),
				showSettingsBtn: true,
			});
			this.removeLoader();
			return;
		}
		let selectedText = await this.getSelectedContent()
		await this.setState({
			selectedText: selectedText
		});

		if(selectedText !== '') {
			localStorage.setItem('selectedText', selectedText);
		}
		let from_template = true;
		if(aich_ajax.pro_activated) {
			Swal.fire({
				title: 'Interested in Simplifying Content Creation with Ready-made Templates?',
				showDenyButton: true,
				showCancelButton: true,
				confirmButtonText: 'Yes',
				denyButtonText: `Stay here`,
				cancelButtonText: `Cancel`,
			}).then((result) => {
				if (result.isConfirmed) {
					let singlePromptTitle = this.string_to_slug(promptTitle);
					let foundedClass = document.getElementsByClassName(singlePromptTitle);
					let appClassName = document.getElementsByClassName('open_ai_templates');
					foundedClass[0].click();
					appClassName[0].click();
				} else if (result.isDenied) {
					if (!this.state.selectedText) {
						Swal.fire({
							icon: 'error',
							title: __('Oops...', 'wp-ai-co-pilot'),
							text: __('Please select text from block', 'wp-ai-co-pilot'),
						});
						return;
					}

					this.loader();

					if(prompt.ai_model === 'dalee') {

						const queryString = window.location.search;
						const urlParams = new URLSearchParams(queryString);
						const post_id = urlParams.get('post');
						let promptobj = {
							'prompt_title': prompt.prompt_title,
							'form_data': [selectedText],
							'prompt_content': prompt.prompt_content,
							'ai_model': prompt.ai_model,
							'prompt': prompt.prompt_content,
							'max_tokens': prompt.word_count,
							'post_id': post_id,
							'additional_data': {
								'toneOfVoice': '',
								'aiAudience': '',
							}
						};

						apiFetch({
							path: 'wp-ai-co-pilot-pro/generate/v1/generate-content',
							method: 'POST',
							body: JSON.stringify(promptobj)
						}).then((res) => {
							this.setState({
								generatedContent: res.contents[0].text
							}, () => {
								this.generatedAndRenderNewBlock();
								this.removeLoader();
							});
						}).catch((error) => {
							this.removeLoader();
							this.setState({
								modalTitle: __('OpenAi Error', 'wp-ai-co-pilot'),
								modalContent: error.message,
								showSettingsBtn: true,
								modalOpen: true
							});
						});
					} else {
						apiFetch({
							path: '/ai-content-helper/openai/v1/generated-content',
							method: 'POST',
							data: {
								requestType: index,
								text: [this.state.selectedText],
								language: lang,
								promptItem:prompt
							},
						}).then((res) => {
							this.setState({
								generatedContent: res.text
							}, () => {
								this.generatedAndRenderNewBlock();
								this.removeLoader();
							});
						}).catch((error) => {
							this.removeLoader();
							this.setState({
								modalTitle: __('OpenAi Error', 'wp-ai-co-pilot'),
								modalContent: error.message,
								showSettingsBtn: true,
								modalOpen: true
							});
						});
					}
				} else if( result.isDismissed) {
					return;
				}
			});
		} else {
			if (!this.state.selectedText) {
				Swal.fire({
					icon: 'error',
					title: __('Oops...', 'wp-ai-co-pilot'),
					text: __('Please select text from block', 'wp-ai-co-pilot'),
				});
				return;
			}

			this.loader();
			// this.props.removePopOver();
			apiFetch({
				path: '/ai-content-helper/openai/v1/generated-content',
				method: 'POST',
				data: {
					requestType: index,
					text: [this.state.selectedText],
					language: lang,
					promptItem:prompt
				},
			}).then((res) => {
				this.setState({
					generatedContent: res.text
				}, () => {
					this.generatedAndRenderNewBlock();
					this.removeLoader();
				});
			}).catch((error) => {
				this.removeLoader();
				this.setState({
					modalTitle: __('OpenAi Error', 'wp-ai-co-pilot'),
					modalContent: error.message,
					showSettingsBtn: true,
					modalOpen: true
				});
			});
		}

	}

	isImage = (url) => {
		return /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(url);
	}

	async autocomplete(autocompleteBlock) {

		let autocompletedTextWithLineBreaks = '';
		if(!this.isImage(this.state.generatedContent)) {
			autocompletedTextWithLineBreaks  = this.state.generatedContent.replace(/\n/g, '<br>');
		} else {
			autocompletedTextWithLineBreaks = `<img src="${this.state.generatedContent}" alt="generated-image" alt="" />`;
		}

		let attributes = autocompleteBlock.attributes;
		attributes.content = autocompletedTextWithLineBreaks;
		wp.data.dispatch('core/block-editor').updateBlock(autocompleteBlock.clientId, attributes);
	}

	async generatedAndRenderNewBlock() {
		const block = await this.createBlockForAutocompletion('bellow');
		await this.autocomplete(block);
	}

	async createBlockForAutocompletion(placement = 'below') {
		let selectedBlockClientIds = this.getSelectedBlockClientIds();
		let [selectionStart, selectionEnd] = this.getAdjustedSelections(selectedBlockClientIds);
		let lastBlockClientId = selectionEnd.clientId;
		let lastBlock = wp.data.select('core/block-editor').getBlock(lastBlockClientId);
		let loadingSpinner = await this.wpAcpLoadingSpinner();

		if (selectedBlockClientIds.length > 1 || lastBlock.name !== 'core/paragraph') {
			let autoCompleteBlock = wp.blocks.createBlock('core/paragraph', {content: loadingSpinner});

			let parentBlockClientId = wp.data.select('core/block-editor').getBlockRootClientId(lastBlockClientId);
			let indexToInsertAt = wp.data.select('core/block-editor').getBlockIndex(lastBlockClientId) + 1;

			if (!wp.data.select('core/block-editor').canInsertBlockType('core/paragraph', parentBlockClientId)) {
				while (parentBlockClientId) {
					indexToInsertAt = wp.data.select('core/block-editor').getBlockIndex(parentBlockClientId) + 1;
					parentBlockClientId = wp.data.select('core/block-editor').getBlockRootClientId(parentBlockClientId);
					if (wp.data.select('core/block-editor').canInsertBlockType('core/paragraph', parentBlockClientId)) {
						break;
					}
				}
			}

			// insert after the last block
			await wp.data.dispatch('core/block-editor').insertBlock(
				autoCompleteBlock,
				indexToInsertAt,
				parentBlockClientId
			);

			return autoCompleteBlock;
		}

		let parentBlockClientId = wp.data.select('core/block-editor').getBlockRootClientId(lastBlockClientId);
		if (!wp.data.select('core/block-editor').canInsertBlockType('core/paragraph', parentBlockClientId)) {
			while (parentBlockClientId) {
				parentBlockClientId = wp.data.select('core/block-editor').getBlockRootClientId(parentBlockClientId);
				if (wp.data.select('core/block-editor').canInsertBlockType('core/paragraph', parentBlockClientId)) {
					break;
				}
			}

			let autoCompleteBlock = wp.blocks.createBlock('core/paragraph', {content: loadingSpinner});

			// insert the block at the end of the parent block
			await wp.data.dispatch('core/block-editor').insertBlock(autoCompleteBlock, undefined, parentBlockClientId);

			return autoCompleteBlock;
		}

		let lastBlockContent = this.extractBlockContent(lastBlock);
		let richText = wp.richText.create({html: lastBlockContent});

		let start = 0;
		let end = lastBlockContent.length;

		if ('offset' in selectionEnd) {
			end = selectionEnd.offset;
		}

		let firstPart = wp.richText.slice(richText, start, end);
		let secondPart = wp.richText.slice(richText, end, richText.text.length);

		let firstPartContent = wp.richText.toHTMLString({value: firstPart});
		let secondPartContent = wp.richText.toHTMLString({value: secondPart});

		let inheritedAttributes = lastBlock.attributes;

		// create block with first part
		const key = selectionEnd.attributeKey
		let firstBlockAttributes = inheritedAttributes;
		firstBlockAttributes[key] = firstPartContent;
		const firstPartBlock = wp.blocks.createBlock(lastBlock.name, firstBlockAttributes);

		// create autocomplete block
		let autoCompleteAttributes = inheritedAttributes;
		autoCompleteAttributes[key] = loadingSpinner;
		let autoCompleteBlock = wp.blocks.createBlock('core/paragraph', autoCompleteAttributes);

		// create block with second part
		let secondBlockAttributes = inheritedAttributes;
		secondBlockAttributes[key] = secondPartContent;
		const secondPartBlock = wp.blocks.createBlock(lastBlock.name, secondBlockAttributes);

		let replacementBlocks = [
			firstPartBlock,
			autoCompleteBlock,
			secondPartBlock,
		];

		if (secondPart.text.trim().length === 0) {
			replacementBlocks = [
				firstPartBlock,
				autoCompleteBlock,
			];
		}

		await wp.data.dispatch('core/block-editor').replaceBlock(lastBlockClientId, replacementBlocks);

		return autoCompleteBlock;
	}

	// Close settings modal
	closeModal() {
		this.setState({
			modalOpen: false
		})
	}

	goToSettingsPage() {
		window.location.href = '/wp-admin/admin.php?page=aich-settings#tab=settings';
	}

	render() {
		return (
			<>
				<div className={'wp_ai_prompts_list'}>
					{
						this.props.prompts.new_prompt.map((single_prompt, single_key) => {
							return (
								<div onClick={() => this.fetchRestApiGenerateContent(this.props.lang, single_key, single_prompt, single_prompt.prompt_title)}
										 className={'single_item'} key={single_key}>{single_prompt.prompt_title} </div>
							)
						})
					}
				</div>

				{this.state.modalOpen && (
					<Modal title={this.state.modalTitle} onRequestClose={() => this.setState({modalOpen: false})}>
						<p>{this.state.modalContent}</p>
						{this.state.showSettingsBtn &&
							<Button variant="primary" onClick={this.goToSettingsPage}>Settings</Button>
						}
					</Modal>
				)}
			</>
		);
	}
}

export default SinglePromptItem;
